// src/utils/extractDataFromQR.ts

import i18next from 'i18next';

export interface ExtractedData {
  firstName?: string;
  lastName?: string;
  biNumber?: string;
  birthPlace?: string;
  dateOfBirth?: string;
  gender?: string;
  maritalStatus?: string;
  issueDate?: string;
  expiryDate?: string;
  issuingPlace?: string;
}

const extractDataFromQR = (qrData: string): ExtractedData => {
  console.log('Raw QR data:', qrData);

  const lines = qrData.split(/\r?\n/); // Support both \r\n and \n line endings

  if (lines.length < 11) {
    throw new Error(i18next.t('errors.invalidDataLines', { expected: 11, got: lines.length }));
  }

  return {
    firstName: lines[0],
    lastName: lines[1],
    biNumber: lines[2],
    birthPlace: lines[3],
    dateOfBirth: formatDate(lines[4]),
    gender: lines[5],
    maritalStatus: lines[6],
    issueDate: formatDate(lines[7]),
    expiryDate: formatDate(lines[8]),
    issuingPlace: lines[9],
    // If there's an 11th line, you can include it as needed
  };
};

const formatDate = (dateString: string): string => {
  const [day, month, year] = dateString.split('/');
  return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
};

export default extractDataFromQR;
