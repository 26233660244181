import React, { useState, useRef, useEffect } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import { Mic, Play, Square } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface VoiceCaptureProps {
  onCapture: (audioBlob: Blob | null) => void;
}

const VoiceCapture: React.FC<VoiceCaptureProps> = ({ onCapture }) => {
  const { t } = useTranslation();
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const chunks = useRef<BlobPart[]>([]);

  useEffect(() => {
    return () => {
      stopRecording();
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => {
          track.stop();
          streamRef.current?.removeTrack(track);
        });
      }
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }
    };
  }, []);

  const startRecording = async () => {
    try {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100
        }
      });
      
      streamRef.current = stream;
      chunks.current = [];
      
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(chunks.current, { type: 'audio/webm' });
        setAudioBlob(blob);
        setIsRecording(false);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Microphone access error:', error);
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
    }
  };

  const playAudio = () => {
    if (audioBlob && audioRef.current) {
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current.src = audioUrl;
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  const handleAdvance = () => {
    if (audioBlob) {
      onCapture(audioBlob);
    }
  };

  const handleSkip = () => {
    onCapture(null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Typography variant="h5">{t('voiceCapture.title')}</Typography>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          value={isRecording ? 100 : 0}
          size={80}
          thickness={4}
          sx={{ color: isRecording ? 'error.main' : 'primary.main' }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Mic size={40} color={isRecording ? 'red' : 'currentColor'} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button
          variant="contained"
          color={isRecording ? 'error' : 'primary'}
          onClick={isRecording ? stopRecording : startRecording}
        >
          {isRecording ? t('voiceCapture.stopRecording') : t('voiceCapture.startRecording')}
        </Button>
        <Button
          variant="outlined"
          onClick={handleSkip}
        >
          {t('voiceCapture.skip')}
        </Button>
      </Box>
      {audioBlob && (
        <>
          <Button
            variant="outlined"
            onClick={isPlaying ? stopAudio : playAudio}
            startIcon={isPlaying ? <Square /> : <Play />}
          >
            {isPlaying ? t('voiceCapture.stopAudio') : t('voiceCapture.playAudio')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAdvance}
          >
            {t('voiceCapture.advance')}
          </Button>
          <audio ref={audioRef} onEnded={() => setIsPlaying(false)} />
        </>
      )}
    </Box>
  );
};

export default VoiceCapture;