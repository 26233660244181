import { parse, differenceInDays, differenceInMonths, differenceInYears, isValid } from 'date-fns';
import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Chip,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Person as UserIcon,
  CreditCard as CreditCardIcon,
  Face as FaceIcon,
  CheckCircle as CheckIcon,
  Cancel as XIcon,
  Help as HelpIcon,
  VolumeUp as VoiceIcon,
  DataObject as DataIcon,
  CloudUpload as UploadIcon,
} from '@mui/icons-material';
import { FaceComparisonResult } from '../utils/faceComparison';
import { useTranslation } from 'react-i18next';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[10],
  },
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
});

interface KYCData {
  personalInfo?: {
    phoneNumber?: string;
    profession?: string;
  };
  documentImageFront?: string;
  documentImageBack?: string;
  faceImage?: string;
  extractedData?: any;
  validationResult?: {
    isValid: boolean;
    isExpired: boolean;
    hasAlterations: boolean;
    errors?: string[];
  };
  livenessResult?: boolean;
  faceComparisonResult?: FaceComparisonResult;
  audioBlob?: Blob;
}

interface KYCResultProps {
  kycData: KYCData;
}

const KYCResult: React.FC<KYCResultProps> = ({ kycData }) => {
  const { t } = useTranslation();

  const getValidationChip = (isValid: boolean | undefined, label: string) => (
    <Chip
      icon={isValid ? <CheckIcon /> : <XIcon />}
      label={label}
      color={isValid ? 'success' : 'error'}
      variant="outlined"
    />
  );

  const getLivenessChip = () => {
    if (typeof kycData.livenessResult === 'boolean') {
      return (
        <Chip
          icon={kycData.livenessResult ? <CheckIcon /> : <XIcon />}
          label={kycData.livenessResult ? t('kycResult.livenessPass') : t('kycResult.livenessFail')}
          color={kycData.livenessResult ? 'success' : 'error'}
          variant="outlined"
        />
      );
    }
    return <Chip label={t('kycResult.livenessNotVerified')} color="default" variant="outlined" />;
  };

  const getFaceComparisonChip = () => {
    if (kycData.faceComparisonResult) {
      const { confidence, match } = kycData.faceComparisonResult;
      return (
        <Chip
          icon={match ? <CheckIcon /> : <XIcon />}
          label={match ? t('kycResult.faceMatch') : t('kycResult.faceNoMatch')}
          color={match ? 'success' : 'error'}
          variant="outlined"
        />
      );
    }
    return <Chip label={t('kycResult.faceNotVerified')} color="default" variant="outlined" />;
  };

 
  const getExpirationInfo = () => {
    if (kycData.extractedData?.expiryDate) {
      const expiryDateStr = kycData.extractedData.expiryDate;
      const expiryDate = parse(expiryDateStr, 'dd/MM/yyyy', new Date());
  
      if (!isValid(expiryDate)) {
        console.error('Invalid expiry date:', expiryDateStr);
        return t('kycResult.invalidExpiryDate');
      }
  
      const today = new Date();
      const diffDays = differenceInDays(expiryDate, today);
      const diffMonths = differenceInMonths(expiryDate, today);
      const diffYears = differenceInYears(expiryDate, today);
  
      if (diffDays < 0) {
        return t('kycResult.expired');
      } else if (diffDays < 30) {
        return t('kycResult.expiresInDays', { days: diffDays });
      } else if (diffMonths < 12) {
        return t('kycResult.expiresInMonths', { months: diffMonths });
      } else {
        return t('kycResult.expiresInYears', { years: diffYears });
      }
    }
    return t('kycResult.expiryDateNotAvailable');
  };

  const renderExtractedData = () => {
    if (!kycData.extractedData) {
      return <Typography variant="body1">{t('kycResult.noExtractedData')}</Typography>;
    }

    return Object.entries(kycData.extractedData).map(([key, value]) => (
      <Typography key={key} variant="body1">
        <strong>{t(`kycResult.extractedFields.${key}`)}:</strong> {value as string}
      </Typography>
    ));
  };

  const exportJSON = () => {
    const dataStr = JSON.stringify(kycData, null, 2);
    const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
    const exportFileDefaultName = 'kyc_result.json';
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  const exportCSV = () => {
    const items = Object.entries(kycData).map(([key, value]) => {
      if (typeof value === 'object') {
        return Object.entries(value).map(([subKey, subValue]) => `${key}.${subKey},${subValue}`).join('\n');
      }
      return `${key},${value}`;
    });
    const csv = items.join('\n');
    const dataUri = 'data:text/csv;charset=utf-8,'+ encodeURIComponent(csv);
    const exportFileDefaultName = 'kyc_result.csv';
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  const sendToAPI = async () => {
    try {
      const response = await fetch('https://api.example.com/kyc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(kycData),
      });
      if (response.ok) {
        alert(t('kycResult.apiSuccess'));
      } else {
        throw new Error('API request failed');
      }
    } catch (error) {
      console.error('Error sending data to API:', error);
      alert(t('kycResult.apiError'));
    }
  };

  return (
    <Box sx={{ 
      maxWidth: '1200px', 
      mx: 'auto', 
      p: 2,
      width: '100%'
    }}>
      <Grid container spacing={3} sx={{ 
        justifyContent: 'center',
        margin: '0 auto',
        width: '100%',
        px: { xs: 2, sm: 3, md: 4 }
      }}>
        <Typography variant="h4" align="center" gutterBottom sx={{ mb: 4 }}>
          {t('kycResult.title')}
        </Typography>

        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardHeader title={t('kycResult.personalInfoTitle')} avatar={<Avatar><UserIcon /></Avatar>} />
            <StyledCardContent>
              <Typography variant="body1">
                <strong>{t('kycResult.phoneNumber')}:</strong> {kycData.personalInfo?.phoneNumber}
              </Typography>
              <Typography variant="body1">
                <strong>{t('kycResult.profession')}:</strong> {t(`professions.${kycData.personalInfo?.profession}`)}
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardHeader
              title={t('kycResult.idDocumentTitle')}
              avatar={<Avatar><CreditCardIcon /></Avatar>}
            />
            <StyledCardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    {t('kycResult.frontID')}
                  </Typography>
                  {kycData.documentImageFront && (
                    <img
                      src={kycData.documentImageFront}
                      alt={t('kycResult.frontIDAlt')}
                      style={{ width: '100%', borderRadius: '4px' }}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    {t('kycResult.backID')}
                  </Typography>
                  {kycData.documentImageBack && (
                    <img
                      src={kycData.documentImageBack}
                      alt={t('kycResult.backIDAlt')}
                      style={{ width: '100%', borderRadius: '4px' }}
                    />
                  )}
                </Grid>
              </Grid>
            </StyledCardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardHeader
              title={t('kycResult.extractedDataTitle')}
              avatar={<Avatar><DataIcon /></Avatar>}
            />
            <StyledCardContent>{renderExtractedData()}</StyledCardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardHeader title={t('kycResult.faceCaptureTitle')} avatar={<Avatar><FaceIcon /></Avatar>} />
            <StyledCardContent>
              {kycData.faceImage ? (
                <img
                  src={kycData.faceImage}
                  alt={t('kycResult.faceCaptureAlt')}
                  style={{ width: '100%', borderRadius: '4px' }}
                />
              ) : (
                <Typography variant="body1">{t('kycResult.noFaceImage')}</Typography>
              )}
            </StyledCardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardHeader
              title={t('kycResult.validationResultsTitle')}
              avatar={<Avatar><CheckIcon /></Avatar>}
            />
            <StyledCardContent>
              <List>
<ListItem>
  <ListItemText 
    primary={t('kycResult.documentExpiration')}
    secondary={getExpirationInfo()}
  />
  {getValidationChip(!kycData.validationResult?.isExpired, kycData.validationResult?.isExpired ? t('kycResult.expired') : t('kycResult.notExpired'))}
</ListItem>
                <ListItem>
                  <ListItemText primary={t('kycResult.documentAlteration')} />
                  {getValidationChip(!kycData.validationResult?.hasAlterations, kycData.validationResult?.hasAlterations ? t('kycResult.altered') : t('kycResult.notAltered'))}
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('kycResult.livenessTest')} />
                  {getLivenessChip()}
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('kycResult.faceComparison')} />
                  {getFaceComparisonChip()}
                </ListItem>
              </List>
              {kycData.faceComparisonResult && (
                <Typography variant="body1">
                  {t('kycResult.faceComparisonConfidence')} {kycData.faceComparisonResult.confidence}%
                </Typography>
              )}
            </StyledCardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12}>
          <StyledCard>
            <StyledCardHeader title={t('kycResult.voiceBiometricsTitle')} avatar={<Avatar><VoiceIcon /></Avatar>} />
            <StyledCardContent>
              {kycData.audioBlob ? (
                <Box>
                  <Typography variant="body1" gutterBottom>
                    {t('kycResult.voiceSampleRecorded')}
                  </Typography>
                  <audio
                    controls
                    src={URL.createObjectURL(kycData.audioBlob)}
                    style={{ width: '100%' }}
                  >
                    {t('kycResult.audioNotSupported')}
                  </audio>
                </Box>
              ) : (
                <Typography variant="body1" color="error">
                  {t('kycResult.noVoiceSample')}
                </Typography>
              )}
            </StyledCardContent>
          </StyledCard>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button variant="contained" color="primary" onClick={exportJSON} startIcon={<UploadIcon />}>
          {t('kycResult.exportJSON')}
        </Button>
        <Button variant="contained" color="secondary" onClick={exportCSV} startIcon={<UploadIcon />}>
          {t('kycResult.exportCSV')}
        </Button>
        <Button variant="contained" color="info" onClick={sendToAPI} startIcon={<UploadIcon />}>
          {t('kycResult.sendToAPI')}
        </Button>
      </Box>
    </Box>
  );
};

export default KYCResult;