import React, { useEffect, useState } from 'react';
import { Box, Stepper, Step, StepLabel, Typography, styled, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import DataCollection from './components/DataCollection';
import DocumentCapture from './components/DocumentCapture';
import FaceCapture from './components/FaceCapture';
import VoiceCapture from './components/VoiceCapture';
import KYCResult from './components/KYCResult';
import { compareFaces, FaceComparisonResult } from './utils/faceComparison';
import { validateDocument, DocumentValidationResult } from './utils/documentValidation';
import { ExtractedData } from './utils/extractDataFromQR';
import { Translate } from '@mui/icons-material';
import logo from './assets/angolaid_logo.png';

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  textAlign: 'center',
  marginTop: 'auto'
}));

interface KYCData {
  personalInfo?: {
    phoneNumber: string;
    profession: string;
  };
  documentImageFront?: string;
  documentImageBack?: string;
  extractedData?: ExtractedData;
  faceImage?: string;
  livenessResult?: boolean;
  faceComparisonResult?: FaceComparisonResult;
  audioBlob?: Blob;
  documentValidationResult?: DocumentValidationResult;
}

const App: React.FC = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [kycData, setKycData] = useState<KYCData>({});

  const steps = [
    t('steps.personalData'),
    t('steps.frontID'),
    t('steps.backID'),
    t('steps.faceCaptureStep'),
    t('steps.voiceCaptureStep'),
    t('steps.result'),
  ];

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleData = (data: Partial<KYCData>) => {
    setKycData((prevData) => {
      const newData = { ...prevData, ...data };
      if (newData.extractedData && newData.livenessResult !== undefined && newData.faceComparisonResult) {
        newData.documentValidationResult = validateDocument(
          newData.extractedData,
          newData.livenessResult,
          newData.faceComparisonResult
        );
      }
      return newData;
    });
    handleNext();
  };

  const handleFaceCapture = async (imageSrc: string, livenessResult: boolean) => {
    try {
      let faceComparisonResult: FaceComparisonResult | undefined;
      if (kycData.documentImageFront) {
        faceComparisonResult = await compareFaces(kycData.documentImageFront, imageSrc);
      }
      handleData({
        faceImage: imageSrc,
        livenessResult,
        faceComparisonResult,
      });
    } catch (error) {
      console.error('Error during face capture:', error);
      // Handle error appropriately
    }
  };

  const handleVoiceCapture = (audioBlob: Blob | null) => {
    handleData({ audioBlob: audioBlob || undefined });
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const newLang = event.target.value;
    i18n.changeLanguage(newLang);
    localStorage.setItem('preferredLanguage', newLang);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
      maxWidth: '100vw',
      overflow: 'hidden'
    }}>
      <Box sx={{ 
        flexGrow: 1, 
        padding: { xs: 1, sm: 2, md: 3 }, 
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Box sx={{ mb: 2 }}>
          <img src={logo} alt="Logo AngolaID" style={{ height: '60px' }} />
        </Box>
        <Box sx={{ 
          textAlign: 'center',
          mb: 3,
          mt: 2
        }}>
          <Typography 
            variant="h3" 
            sx={{ 
              background: 'var(--gradient-primary)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              fontWeight: 900,
              fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
              mb: 2,
              letterSpacing: '0.02em',
              textTransform: 'uppercase',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '-8px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '60%',
                height: '4px',
                background: 'var(--gradient-primary)',
                borderRadius: '2px'
              }
            }}
          >
            {t('title')}
          </Typography>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              color: 'primary.main',
              fontWeight: 'bold',
              fontSize: { xs: '0.9rem', sm: '1rem' }
            }}
          >
            {t('betaMessage')}
          </Typography>
          <Typography 
            variant="subtitle2" 
            sx={{ 
              color: 'text.secondary',
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              mt: 0.5
            }}
          >
            {t('betaSubtitle')}
          </Typography>
        </Box>
        <Box sx={{ 
          width: '100%',
          maxWidth: 'md',
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 2 
        }}>
          <Select
            value={i18n.language}
            onChange={handleLanguageChange}
            sx={{ 
              minWidth: { xs: 100, sm: 120 },
              maxWidth: { xs: 120, sm: 150 }
            }}
          >
            <MenuItem value="pt">🇵🇹 PT</MenuItem>
            <MenuItem value="en">🇬🇧 EN</MenuItem>
            <MenuItem value="fr">🇫🇷 FR</MenuItem>
            <MenuItem value="ar">🇸🇦 AR</MenuItem>
            <MenuItem value="zh">🇨🇳 ZH</MenuItem>
            <MenuItem value="es">🇪🇸 ES</MenuItem>
            <MenuItem value="sw">🇰🇪 SW</MenuItem>
            <MenuItem value="hd">🇮🇳 HI</MenuItem>
          </Select>
        </Box>
        <Box sx={{ 
          width: '100%',
          maxWidth: 'md',
          overflowX: 'hidden',
          px: { xs: 1, sm: 2 }
        }}>
          <Stepper 
            activeStep={activeStep} 
            alternativeLabel
            sx={{
              '& .MuiStepLabel-label': {
                fontSize: { xs: '0.75rem', sm: '0.875rem' }
              }
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box sx={{ 
          width: '100%',
          maxWidth: 'md',
          mt: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          {activeStep === 0 && <DataCollection onComplete={handleData} />}
          {activeStep === 1 && (
            <DocumentCapture
              onCapture={(image, extractedData) => handleData({ documentImageFront: image, extractedData })}
              isFrontCapture={true}
            />
          )}
          {activeStep === 2 && (
            <DocumentCapture
              onCapture={(image, extractedData) => handleData({
                documentImageBack: image,
                extractedData: { ...kycData.extractedData, ...extractedData },
              })}
              isFrontCapture={false}
            />
          )}
          {activeStep === 3 && <FaceCapture onCapture={handleFaceCapture} />}
          {activeStep === 4 && <VoiceCapture onCapture={handleVoiceCapture} />}
          {activeStep === 5 && <KYCResult kycData={kycData} />}
        </Box>
      </Box>
      <Footer>
        <Typography variant="body2">
          {t('footer.developedBy')}{' '}
          <a
            href="https://www.techbytech.tech"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'inherit', textDecoration: 'underline' }}
          >
            www.techbytech.tech
          </a>{' '}
          🥷🏾👨🏾‍💻👩🏾‍💻🤖
        </Typography>
      </Footer>
    </Box>
  );
};

export default App;