// src/utils/documentValidation.ts

import { ExtractedData } from './extractDataFromQR';
import { FaceComparisonResult } from './faceComparison';

export interface DocumentValidationResult {
  isValid: boolean;
  isExpired: boolean;
  hasAlterations: boolean;
  livenessTestPassed: boolean;
  faceMatchResult: FaceComparisonResult | null;
  errors: string[];
}

export function validateDocument(
  extractedData: ExtractedData,
  livenessTestResult: boolean,
  faceComparisonResult: FaceComparisonResult | undefined
): DocumentValidationResult {
  const result: DocumentValidationResult = {
    isValid: true,
    isExpired: false,
    hasAlterations: false,
    livenessTestPassed: livenessTestResult,
    faceMatchResult: faceComparisonResult || null,
    errors: [],
  };

  // Check document validity (including expiration)
  if (extractedData.expiryDate) {
    result.isExpired = checkExpiration(extractedData.expiryDate);
    if (result.isExpired) {
      result.errors.push('Document is expired');
      result.isValid = false;
    }
  } else {
    result.errors.push('Expiry date not available');
    result.isValid = false;
  }

  // Check for alterations (simplified check)
  result.hasAlterations = checkForAlterations(extractedData);
  if (result.hasAlterations) {
    result.errors.push('Signs of alteration detected');
    result.isValid = false;
  }

  // Liveness test result is already set

  // Face comparison result is already set

  return result;
}

function checkExpiration(expiryDateString: string): boolean {
  const expiryDate = new Date(expiryDateString);
  const currentDate = new Date();
  return expiryDate < currentDate;
}

function checkForAlterations(extractedData: ExtractedData): boolean {
  // This is a simplified check. In a real-world scenario, you'd use more sophisticated techniques.
  const requiredFields = ['firstName', 'lastName', 'biNumber', 'dateOfBirth', 'expiryDate'];
  for (const field of requiredFields) {
    if (!extractedData[field as keyof ExtractedData]) {
      return true; // Consider it altered if any required field is missing
    }
  }
  
  // Add any additional checks here, e.g., format validation for specific fields
  
  return false;
}