// index.tsx
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';

// Comment out or remove TensorFlow initialization
// import * as tf from '@tensorflow/tfjs';
// tf.setBackend('webgl').then(() => { ... });

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);