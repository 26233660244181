import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './locales/en.json';
import ptTranslations from './locales/pt.json';
import frTranslations from './locales/fr.json';
import arTranslations from './locales/ar.json';
import zhTranslations from './locales/zh.json';
import esTranslations from './locales/es.json';
import swTranslations from './locales/sw.json';
import hdTranslations from './locales/hd.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      pt: { translation: ptTranslations },
      fr: { translation: frTranslations },
      ar: { translation: arTranslations },
      zh: { translation: zhTranslations },
      es: { translation: esTranslations },
      sw: { translation: swTranslations },
      hd: { translation: hdTranslations },
    },
    lng: 'pt', // Set Portuguese as the default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;