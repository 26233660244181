// src/components/DocumentCapture.tsx

import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Box, Button, Typography, CircularProgress, Tooltip, useTheme } from '@mui/material';
import { 
  CameraAlt as CameraAltIcon, 
  Person, 
  Badge, 
  QrCode2, 
  Fingerprint,
  Replay as ReplayIcon,
  WbSunny as WbSunnyIcon,
  Brightness2 as Brightness2Icon
} from '@mui/icons-material';
import extractDataFromQR, { ExtractedData } from '../utils/extractDataFromQR';
import jsQR from 'jsqr';
import { useTranslation } from 'react-i18next';

interface DocumentCaptureProps {
  onCapture: (image: string, extractedData?: ExtractedData) => void;
  isFrontCapture: boolean;
}

const CARD_ASPECT_RATIO = 1.586;
const CARD_WIDTH = 100;
const CARD_HEIGHT = CARD_WIDTH / CARD_ASPECT_RATIO;

const DEFAULT_GUIDES = {
  photoArea: {
    x: CARD_WIDTH * 0.68,
    y: CARD_HEIGHT * 0.25,
    width: CARD_WIDTH * 0.24,
    height: CARD_HEIGHT * 0.35,
  },
  insigniaArea: {
    x: CARD_WIDTH * 0.06,
    y: CARD_HEIGHT * 0.15,
    width: CARD_WIDTH * 0.14,
    height: CARD_HEIGHT * 0.18,
  },
  qrCodeArea: {
    x: CARD_WIDTH * 0.68,
    y: CARD_HEIGHT * 0.60,
    width: CARD_WIDTH * 0.25,
    height: CARD_HEIGHT * 0.25,
  },
  fingerprintArea: {
    x: CARD_WIDTH * 0.68,
    y: CARD_HEIGHT * 0.10,
    width: CARD_WIDTH * 0.27,
    height: CARD_HEIGHT * 0.25,
  },
  barcodeArea: {
    x: CARD_WIDTH * 0.06,
    y: CARD_HEIGHT * 0.80,
    width: CARD_WIDTH * 0.42,
    height: CARD_HEIGHT * 0.12,
  },
};

const SCAN_INTERVAL = 500; // Interval in ms for scanning frames
const MAX_SCAN_ATTEMPTS = 20; // Maximum number of scan attempts before showing error

const DocumentCapture: React.FC<DocumentCaptureProps> = ({ onCapture, isFrontCapture }) => {
  const theme = useTheme();
  const webcamRef = useRef<Webcam>(null);
  const { t } = useTranslation(); // Initialize translation
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [scanAttempts, setScanAttempts] = useState(0);
  const [scanning, setScanning] = useState(!isFrontCapture); // Start scanning only for back capture
  const [lighting, setLighting] = useState<'good' | 'dim' | 'bright'>('good'); // Lighting status
  const [position, setPosition] = useState<'good' | 'bad'>('good'); // Position status

  const processFrame = (imageData: ImageData): string | null => {
    const code = jsQR(imageData.data, imageData.width, imageData.height);
    return code?.data || null;
  };

  useEffect(() => {
    let scanIntervalId: NodeJS.Timeout;

    if (isFrontCapture || !scanning || processing || capturedImage) {
      return;
    }

    scanIntervalId = setInterval(() => {
      if (webcamRef.current?.video?.readyState === 4) {
        const video = webcamRef.current.video;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        
        if (context) {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          
          const qrData = processFrame(imageData);
          if (qrData) {
            clearInterval(scanIntervalId);
            handleDetectedQRCode(qrData);
          } else {
            setScanAttempts(prev => {
              const newCount = prev + 1;
              if (newCount >= MAX_SCAN_ATTEMPTS) {
                clearInterval(scanIntervalId);
                setError(t('documentCapture.errorQRCodeNotDetected'));
                setScanning(false);
              }
              return newCount;
            });
          }

          analyzeLighting(imageData.data, imageData.width, imageData.height);
          analyzePosition(imageData, imageData.width, imageData.height);
        }
      }
    }, 300); // Reduced interval for more frequent scanning

    return () => clearInterval(scanIntervalId);
  }, [scanning, processing, capturedImage, isFrontCapture, t]);

  const handleDetectedQRCode = async (qrData: string) => {
    setProcessing(true);
    try {
      console.log('Decoded QR Data:', qrData);
      const extractedData = extractDataFromQR(qrData);
      const imageSrc = webcamRef.current?.getScreenshot();
      if (imageSrc) {
        setCapturedImage(imageSrc);
        onCapture(imageSrc, extractedData);
        setError(null);
      } else {
        throw new Error(t('documentCapture.errorCaptureImage'));
      }
    } catch (err) {
      console.error('Error extracting QR data:', err);
      setError(t('documentCapture.errorExtractData', { error: err instanceof Error ? err.message : String(err) }));
    } finally {
      setProcessing(false);
      setScanning(false);
      setScanAttempts(0);
    }
  };

  const handleCapture = async () => {
    // Manual capture for front capture or as a fallback for back capture
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setCapturedImage(imageSrc);
      setProcessing(true);
      try {
        if (!isFrontCapture) {
          // Attempt to decode QR code manually
          const qrData = await decodeQRCode(imageSrc);
          if (!qrData) {
            throw new Error(t('documentCapture.errorQRCodeNotDetected'));
          }
          const extractedData = extractDataFromQR(qrData);
          onCapture(imageSrc, extractedData);
        } else {
          onCapture(imageSrc);
        }
        setError(null);
      } catch (err) {
        console.error('Error extracting QR data:', err);
        setError(t('documentCapture.errorExtractData', { error: err instanceof Error ? err.message : String(err) }));
      } finally {
        setProcessing(false);
      }
    } else {
      setError(t('documentCapture.errorCaptureImage'));
    }
  };

  // Fallback QR decoding from image (manual capture)
  const decodeQRCode = async (imageSrc: string): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext('2d');
        if (!context) {
          reject(new Error(t('documentCapture.errorCaptureImage')));
          return;
        }
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const qrCode = processFrame(imageData);
        if (qrCode) {
          resolve(qrCode);
        } else {
          resolve(null); // No QR code found
        }
      };
      image.onerror = () => {
        reject(new Error(t('documentCapture.errorExtractData', { error: 'Image load failed' })));
      };
    });
  };

  const handleRetry = () => {
    setError(null);
    setCapturedImage(null);
    setScanAttempts(0);
    setScanning(true);
    setLighting('good');
    setPosition('good');
  };

  // Function to analyze lighting using average brightness
  const analyzeLighting = (data: Uint8ClampedArray, width: number, height: number) => {
    let totalBrightness = 0;
    const sampleSize = 1000; // Number of pixels to sample
    const step = Math.floor((width * height) / sampleSize);
    for (let i = 0; i < data.length; i += step * 4) { // RGBA, step*4
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      const brightness = (r + g + b) / 3;
      totalBrightness += brightness;
    }
    const avgBrightness = totalBrightness / sampleSize;
    if (avgBrightness < 100) {
      setLighting('dim');
    } else if (avgBrightness > 200) {
      setLighting('bright');
    } else {
      setLighting('good');
    }
  };

  // Function to analyze positioning by checking the photoArea
  const analyzePosition = (imageData: ImageData, width: number, height: number) => {
    const { photoArea } = DEFAULT_GUIDES;
    const startX = Math.floor(photoArea.x / CARD_WIDTH * width);
    const startY = Math.floor(photoArea.y / CARD_HEIGHT * height);
    const areaWidth = Math.floor(photoArea.width / CARD_WIDTH * width);
    const areaHeight = Math.floor(photoArea.height / CARD_HEIGHT * height);

    let areaBrightness = 0;
    let areaPixelCount = 0;

    for (let y = startY; y < startY + areaHeight; y++) {
      for (let x = startX; x < startX + areaWidth; x++) {
        const index = (y * width + x) * 4;
        const r = imageData.data[index];
        const g = imageData.data[index + 1];
        const b = imageData.data[index + 2];
        const brightness = (r + g + b) / 3;
        areaBrightness += brightness;
        areaPixelCount++;
      }
    }

    const avgAreaBrightness = areaBrightness / areaPixelCount;

    // Simple logic: if brightness within photoArea is similar to overall, assume good positioning
    if (avgAreaBrightness < 80 || avgAreaBrightness > 220) {
      setPosition('bad');
    } else {
      setPosition('good');
    }
  };

  // Function to determine guide color based on lighting and position
  const getGuideColor = () => {
    if (lighting === 'dim' || lighting === 'bright' || position === 'bad') {
      return '#FFA500'; // Light Orange for issues
    }
    return '#FFA500'; // Light Orange when all is good (same color as per request)
    // If you want different colors for different states, adjust accordingly
    // For example:
    // return (lighting === 'good' && position === 'good') ? '#FFA500' : '#FFA500';
  };

  const renderGuides = () => {
    return (
      <>
        <svg
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
          }}
          viewBox={`0 0 ${CARD_WIDTH} ${CARD_HEIGHT}`}
          preserveAspectRatio="none"
        >
          {/* Card outline */}
          <rect
            x="2"
            y="2"
            width={CARD_WIDTH - 4}
            height={CARD_HEIGHT - 4}
            fill="none"
            stroke={getGuideColor()}
            strokeWidth="1.5" // Thinner lines
            strokeDasharray="4,4"
            opacity="0.9"
          />
        </svg>

        {!isFrontCapture ? (
          // Back of ID guides
          <>
            {/* Barcode area - Left Bottom */}
            <Box
              sx={{
                position: 'absolute',
                left: `${DEFAULT_GUIDES.barcodeArea.x * 100 / CARD_WIDTH}%`,
                top: `${DEFAULT_GUIDES.barcodeArea.y * 100 / CARD_HEIGHT}%`,
                width: `${DEFAULT_GUIDES.barcodeArea.width * 100 / CARD_WIDTH}%`,
                height: `${DEFAULT_GUIDES.barcodeArea.height * 100 / CARD_HEIGHT}%`,
                border: `2px dashed ${getGuideColor()}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tooltip title={t('documentCapture.tooltipPositionQRCode')} placement="top" arrow>
                <svg
                  width="90%"
                  height="80%"
                  viewBox="0 0 120 30"
                  opacity="0.8"
                >
                  <g fill="#ffffff">
                    {/* Example barcode lines */}
                    <rect x="0" y="0" width="3" height="30" />
                    <rect x="5" y="0" width="1" height="30" />
                    <rect x="8" y="0" width="2" height="30" />
                    <rect x="12" y="0" width="3" height="30" />
                    <rect x="17" y="0" width="2" height="30" />
                    <rect x="21" y="0" width="4" height="30" />
                    <rect x="27" y="0" width="1" height="30" />
                    <rect x="30" y="0" width="3" height="30" />
                    <rect x="35" y="0" width="2" height="30" />
                    <rect x="39" y="0" width="4" height="30" />
                    <rect x="45" y="0" width="1" height="30" />
                    <rect x="48" y="0" width="3" height="30" />
                    <rect x="53" y="0" width="2" height="30" />
                    <rect x="57" y="0" width="4" height="30" />
                    <rect x="63" y="0" width="1" height="30" />
                    <rect x="66" y="0" width="2" height="30" />
                    <rect x="70" y="0" width="3" height="30" />
                    <rect x="75" y="0" width="4" height="30" />
                    <rect x="81" y="0" width="1" height="30" />
                    <rect x="84" y="0" width="3" height="30" />
                    <rect x="89" y="0" width="2" height="30" />
                    <rect x="93" y="0" width="3" height="30" />
                    <rect x="98" y="0" width="1" height="30" />
                    <rect x="101" y="0" width="4" height="30" />
                    <rect x="107" y="0" width="2" height="30" />
                    <rect x="111" y="0" width="3" height="30" />
                    <rect x="116" y="0" width="1" height="30" />
                  </g>
                </svg>
              </Tooltip>
            </Box>

            {/* Fingerprint area - Right Top */}
            <Box
              sx={{
                position: 'absolute',
                left: `${DEFAULT_GUIDES.fingerprintArea.x * 100 / CARD_WIDTH}%`,
                top: `${DEFAULT_GUIDES.fingerprintArea.y * 100 / CARD_HEIGHT}%`,
                width: `${DEFAULT_GUIDES.fingerprintArea.width * 100 / CARD_WIDTH}%`,
                height: `${DEFAULT_GUIDES.fingerprintArea.height * 100 / CARD_HEIGHT}%`,
                border: `2px dashed ${getGuideColor()}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tooltip title={t('documentCapture.tooltipPositionFinger')} placement="top" arrow>
                <Fingerprint 
                  sx={{ 
                    width: '70%', 
                    height: '70%',
                    color: 'rgba(255, 165, 0, 0.8)', // Light Orange
                  }} 
                />
              </Tooltip>
            </Box>

            {/* QR Code area - Below Fingerprint */}
            <Box
              sx={{
                position: 'absolute',
                left: `${DEFAULT_GUIDES.qrCodeArea.x * 100 / CARD_WIDTH}%`,
                top: `${DEFAULT_GUIDES.qrCodeArea.y * 100 / CARD_HEIGHT}%`,
                width: `${DEFAULT_GUIDES.qrCodeArea.width * 100 / CARD_WIDTH}%`,
                height: `${DEFAULT_GUIDES.qrCodeArea.height * 100 / CARD_HEIGHT}%`,
                border: `2px dashed ${getGuideColor()}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tooltip title={t('documentCapture.tooltipPositionQRCode')} placement="top" arrow>
                <QrCode2 
                  sx={{ 
                    width: '70%', 
                    height: '70%',
                    color: 'rgba(255, 165, 0, 0.8)', // Light Orange
                  }} 
                />
              </Tooltip>
            </Box>
          </>
        ) : (
          // Front of ID guides
          <>
            {/* Photo area with square outline */}
            <Box
              sx={{
                position: 'absolute',
                left: `${DEFAULT_GUIDES.photoArea.x * 100 / CARD_WIDTH}%`,
                top: `${DEFAULT_GUIDES.photoArea.y * 100 / CARD_HEIGHT}%`,
                width: `${DEFAULT_GUIDES.photoArea.width * 100 / CARD_WIDTH}%`,
                height: `${DEFAULT_GUIDES.photoArea.height * 100 / CARD_HEIGHT}%`,
                border: `2px dashed ${getGuideColor()}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tooltip title={t('documentCapture.tooltipPositionPhoto')} placement="top" arrow>
                <Person 
                  sx={{ 
                    width: '70%', 
                    height: '70%',
                    color: 'rgba(255, 165, 0, 0.8)', // Light Orange
                  }} 
                />
              </Tooltip>
            </Box>

            {/* Insignia area with circular outline */}
            <Box
              sx={{
                position: 'absolute',
                left: `${DEFAULT_GUIDES.insigniaArea.x * 100 / CARD_WIDTH}%`,
                top: `${DEFAULT_GUIDES.insigniaArea.y * 100 / CARD_HEIGHT}%`,
                width: `${DEFAULT_GUIDES.insigniaArea.width * 100 / CARD_WIDTH}%`,
                height: `${DEFAULT_GUIDES.insigniaArea.height * 100 / CARD_HEIGHT}%`,
                border: `2px dashed ${getGuideColor()}`,
                borderRadius: '50%', // Circular outline for insignia
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tooltip title={t('documentCapture.tooltipInsignia')} placement="top" arrow>
                <Badge 
                  sx={{ 
                    width: '70%', 
                    height: '70%',
                    color: 'rgba(255, 165, 0, 0.8)', // Light Orange
                  }} 
                />
              </Tooltip>
            </Box>
            {/* Barcode area is not present on the front */}
          </>
        )}
      </>
    );}

    const renderHints = () => {
      // Position the hints below the capture button
      return (
        <Box sx={{ 
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}>
          {/* Lighting Feedback */}
          <Box sx={{ 
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            padding: '8px 16px',
            borderRadius: '4px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            maxWidth: '90%',
          }}>
            {lighting === 'dim' && (
              <>
                <Brightness2Icon />
                <Typography sx={{ color: 'error.main' }}>
                  {t('documentCapture.feedbackLightingLow')}
                </Typography>
              </>
            )}
            {lighting === 'bright' && (
              <>
                <WbSunnyIcon />
                <Typography sx={{ color: 'warning.main' }}>
                  {t('documentCapture.feedbackLightingHigh')}
                </Typography>
              </>
            )}
            {lighting === 'good' && (
              <>
                <WbSunnyIcon />
                <Typography sx={{ color: 'success.main' }}>
                  {t('documentCapture.feedbackLightingGood')}
                </Typography>
              </>
            )}
          </Box>

          {/* Positioning Feedback */}
          {!isFrontCapture && (
            <Box sx={{ 
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              padding: '8px 16px',
              borderRadius: '4px',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              maxWidth: '90%',
            }}>
              {position === 'bad' ? (
                <>
                  <Brightness2Icon />
                  <Typography sx={{ color: 'error.main' }}>
                    {t('documentCapture.feedbackPositionBad')}
                  </Typography>
                </>
              ) : (
                <>
                  <WbSunnyIcon />
                  <Typography sx={{ color: 'success.main' }}>
                    {t('documentCapture.feedbackPositionGood')}
                  </Typography>
                </>
              )}
            </Box>
          )}

          {/* Main Feedback Message */}
          {error && (
            <Box sx={{ 
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              padding: '12px 20px',
              borderRadius: '8px',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              maxWidth: '90%',
            }}>
              <Brightness2Icon />
              <Typography sx={{ color: 'error.main' }}>
                {error}
              </Typography>
            </Box>
          )}

          {processing && (
            <Box sx={{ 
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              padding: '12px 20px',
              borderRadius: '8px',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              maxWidth: '90%',
            }}>
              <WbSunnyIcon />
              <Typography sx={{ color: 'primary.main' }}>
                {isFrontCapture 
                  ? t('documentCapture.processingFront') 
                  : t('documentCapture.processingBack')}
              </Typography>
            </Box>
          )}

          {capturedImage && (
            <Box sx={{ 
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              padding: '12px 20px',
              borderRadius: '8px',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              maxWidth: '90%',
            }}>
              <WbSunnyIcon />
              <Typography sx={{ color: 'success.main' }}>
                {isFrontCapture 
                  ? t('documentCapture.successFront') 
                  : t('documentCapture.successBack')}
              </Typography>
            </Box>
          )}
        </Box>
      );
    };

    return (
      <Box sx={{ maxWidth: 600, mx: 'auto', p: 3, textAlign: 'center', position: 'relative' }}>
        <Typography variant="h5" gutterBottom>
          {t(`documentCapture.title${isFrontCapture ? 'Front' : 'Back'}`)}
        </Typography>

        {/* Webcam and Guides */}
        <Box sx={{ 
          position: 'relative', 
          width: '100%', 
          paddingTop: `${100 / CARD_ASPECT_RATIO}%`, // Maintain aspect ratio
          mb: 2,
          border: '2px solid',
          borderColor: theme.palette.primary.main,
          borderRadius: 2,
          overflow: 'hidden',
          boxShadow: 3,
          backgroundColor: '#000', // Background color to handle loading state
        }}>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: 'environment',
              aspectRatio: CARD_ASPECT_RATIO,
            }}
            style={{ 
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%', 
              height: '100%',
              objectFit: 'cover',
            }}
          />
          {renderGuides()}
          {processing && (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 2,
            }}>
              <CircularProgress sx={{ color: 'white' }} />
            </Box>
          )}
        </Box>

        {/* Capture Button */}
        {!processing && !capturedImage && (
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCapture} 
            startIcon={<CameraAltIcon />}
            disabled={
              isFrontCapture 
                ? lighting !== 'good' 
                : lighting !== 'good' || position !== 'good'
            } // Disable if criteria are not met
            sx={{ 
              px: 4,
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '1.1rem',
              boxShadow: 2,
            }}
          >
            {t(`documentCapture.buttonCapture${isFrontCapture ? 'Front' : 'Back'}`)}
          </Button>
        )}

        {/* Hints and Feedback Messages */}
        {renderHints()}

        {/* Retry Button on Error */}
        {error && (
          <Box sx={{ mt: 2 }}>
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={handleRetry} 
              startIcon={<ReplayIcon />}
              sx={{ 
                px: 4,
                py: 1.5,
                borderRadius: 2,
                textTransform: 'none',
                fontSize: '1.1rem',
              }}
            >
              {t('documentCapture.buttonRetry')}
            </Button>
          </Box>
        )}
      </Box>
    );

};

export default DocumentCapture;
