import * as faceapi from 'face-api.js';

export async function compareFaces(documentImage: string, selfieImage: string): Promise<FaceComparisonResult> {
  try {
    // Load models if not already loaded
    await loadRequiredModels();

    // Load images
    const img1 = await faceapi.fetchImage(documentImage);
    const img2 = await faceapi.fetchImage(selfieImage);

    // Validate image dimensions
    if (!img1.width || !img1.height || !img2.width || !img2.height) {
      throw new Error('Invalid image dimensions');
    }

    // Detect faces with validation
    const detection1 = await detectFaceWithValidation(img1);
    const detection2 = await detectFaceWithValidation(img2);

    if (!detection1 || !detection2) {
      return {
        match: false,
        confidence: 0,
        error: 'No face detected in one or both images'
      };
    }

    // Get face descriptors
    const descriptor1 = detection1.descriptor;
    const descriptor2 = detection2.descriptor;

    // Calculate face similarity
    const distance = faceapi.euclideanDistance(descriptor1, descriptor2);
    const confidence = Math.round((1 - distance) * 100); // Convert to percentage and round to whole number
    const match = confidence > 50; // Threshold now at 50%

    return {
      match,
      confidence,
      error: null
    };
  } catch (error) {
    console.error('Face comparison error:', error);
    return {
      match: false,
      confidence: 0,
      error: error instanceof Error ? error.message : 'Unknown error during face comparison'
    };
  }
}

async function detectFaceWithValidation(image: HTMLImageElement) {
  try {
    // Ensure image dimensions are valid
    if (!image.width || !image.height) {
      throw new Error('Invalid image dimensions');
    }

    // Get detections with face landmarks and descriptors
    const detections = await faceapi
      .detectSingleFace(image)
      .withFaceLandmarks()
      .withFaceDescriptor();

    if (!detections) {
      return null;
    }

    // Validate bounding box
    const { box } = detections.detection;
    if (!isValidBox(box)) {
      throw new Error('Invalid face detection bounding box');
    }

    return detections;
  } catch (error) {
    console.error('Face detection error:', error);
    return null;
  }
}

function isValidBox(box: faceapi.Box): boolean {
  return (
    typeof box.x === 'number' &&
    typeof box.y === 'number' &&
    typeof box.width === 'number' &&
    typeof box.height === 'number' &&
    !isNaN(box.x) &&
    !isNaN(box.y) &&
    !isNaN(box.width) &&
    !isNaN(box.height) &&
    box.width > 0 &&
    box.height > 0
  );
}

async function loadRequiredModels() {
  try {
    // Check if models are already loaded
    if (!isFaceDetectionModelLoaded()) {
      await Promise.all([
        faceapi.nets.ssdMobilenetv1.loadFromUri('/models'),
        faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
        faceapi.nets.faceRecognitionNet.loadFromUri('/models')
      ]);
    }
  } catch (error) {
    console.error('Error loading face-api models:', error);
    throw new Error('Failed to load face detection models');
  }
}

function isFaceDetectionModelLoaded(): boolean {
  return (
    faceapi.nets.ssdMobilenetv1.isLoaded &&
    faceapi.nets.faceLandmark68Net.isLoaded &&
    faceapi.nets.faceRecognitionNet.isLoaded
  );
}

export interface FaceComparisonResult {
  match: boolean;
  confidence: number;
  error: string | null;
}