import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';
import { Box, Button, Typography, CircularProgress, LinearProgress } from '@mui/material';
import { isInIOSApp } from '../utils/platformDetection';

interface FaceCaptureProps {
  onCapture: (imageSrc: string, livenessResult: boolean) => Promise<void>;
}

const FaceCapture: React.FC<FaceCaptureProps> = ({ onCapture }) => {
  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isCapturing, setIsCapturing] = useState(false);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [instruction, setInstruction] = useState('');
  const [progress, setProgress] = useState(0);
  const [countdown, setCountdown] = useState(5);
  const [livenessPassed, setLivenessPassed] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    loadModels();
  }, []);

  useEffect(() => {
    let intervalId: number;

    if (modelsLoaded) {
      intervalId = window.setInterval(() => {
        detectFace();
      }, 100);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [modelsLoaded]);

  const loadModels = async () => {
    const MODEL_URL = 'https://justadudewhohacks.github.io/face-api.js/models';
    try {
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
        faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
      ]);
      console.log('All models loaded successfully');
      setModelsLoaded(true);
    } catch (error) {
      console.error('Error loading models:', error);
    }
  };

  const detectFace = async () => {
    if (
      webcamRef.current &&
      webcamRef.current.video &&
      webcamRef.current.video.readyState === 4
    ) {
      const video = webcamRef.current.video;
      const canvas = canvasRef.current;

      const displaySize = { width: video.videoWidth, height: video.videoHeight };

      faceapi.matchDimensions(canvas!, displaySize);

      try {
        const detections = await faceapi
          .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks();

        if (detections && detections.length > 0) {
          const resizedDetections = faceapi.resizeResults(detections, displaySize);

          canvas!.getContext('2d')!.clearRect(0, 0, canvas!.width, canvas!.height);

          resizedDetections.forEach((detection) => {
            const landmarks = detection.landmarks;
            const drawLandmarks = new faceapi.draw.DrawFaceLandmarks(landmarks, {
              drawLines: true,
              lineWidth: 2,
              lineColor: 'yellow',
              drawPoints: true,
              pointSize: 2,
              pointColor: 'red',
            });
            drawLandmarks.draw(canvas!);
          });
        } else {
          canvas!.getContext('2d')!.clearRect(0, 0, canvas!.width, canvas!.height);
        }
      } catch (error) {
        console.error('Error during face detection:', error);
      }
    }
  };

  const startLivenessCheck = () => {
    setIsCapturing(true);
    setProgress(0);
    setCountdown(5);
    setInstruction('');
    setLivenessPassed(false);
    startCountdown();
  };

  const startCountdown = () => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount === 1) {
          clearInterval(countdownInterval);
          performLivenessCheck();
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const performLivenessCheck = async () => {
    const checks = [
      { instruction: t('faceCapture.neutralExpression'), check: checkNeutralExpression, duration: 3000 },
      { instruction: t('faceCapture.smile'), check: checkSmile, duration: 3000 },
    ];

    for (let i = 0; i < checks.length; i++) {
      const { instruction, check, duration } = checks[i];
      setInstruction(instruction);

      const startTime = Date.now();
      let passed = false;

      while (Date.now() - startTime < duration) {
        passed = await performCheck(check);
        if (passed) break;
        setProgress(((Date.now() - startTime) / duration) * 100);
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      if (!passed) {
        setIsCapturing(false);
        setInstruction(t('faceCapture.verificationFailed'));
        return;
      }

      setProgress(((i + 1) / checks.length) * 100);
    }

    setInstruction(t('faceCapture.verificationCompleted'));
    setLivenessPassed(true);
    setIsCapturing(false);
  };

  const performCheck = async (checkFunction: (img: HTMLImageElement) => Promise<boolean>) => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      const img = new Image();
      img.src = imageSrc;
      await new Promise<void>((resolve, reject) => {
        img.onload = () => resolve();
        img.onerror = (err) => reject(err);
      });
      return await checkFunction(img);
    }
    return false;
  };

  const handleCapture = async () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setInstruction(t('faceCapture.imageCaptures'));
      await onCapture(imageSrc, livenessPassed);
    } else {
      console.error('Failed to capture image');
      setInstruction(t('faceCapture.captureError'));
    }
    setIsCapturing(false);
  };

  async function checkNeutralExpression(img: HTMLImageElement) {
    const detection = await faceapi
      .detectSingleFace(img, new faceapi.TinyFaceDetectorOptions())
      .withFaceExpressions();
    return (detection?.expressions?.neutral ?? 0) > 0.7;
  }

  async function checkSmile(img: HTMLImageElement) {
    const detection = await faceapi
      .detectSingleFace(img, new faceapi.TinyFaceDetectorOptions())
      .withFaceExpressions();
    return (detection?.expressions?.happy ?? 0) > 0.7;
  }

  if (!modelsLoaded) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {t('faceCapture.loadingModels')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3, textAlign: 'center', position: 'relative' }}>
      <Typography variant="h5" gutterBottom>
        {t('faceCapture.title')}
      </Typography>
      <Box sx={{ position: 'relative', width: '100%', height: 'auto', mb: 2 }}>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{
            facingMode: isInIOSApp() ? "environment" : "user",
            width: { ideal: 1280 },
            height: { ideal: 720 }
          }}
          onUserMediaError={(err) => {
            console.error('Camera access error:', err);
            setError(t('faceCapture.cameraError'));
          }}
          style={{ width: '100%', height: 'auto' }}
        />
        <canvas
          ref={canvasRef}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
        {countdown > 0 && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '48px',
              fontWeight: 'bold',
              color: 'white',
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
            }}
          >
            {countdown}
          </Box>
        )}
      </Box>
      <Typography variant="h6" gutterBottom>
        {instruction || (countdown > 0 ? t('faceCapture.positionFace') : '')}
      </Typography>
      <LinearProgress variant="determinate" value={progress} sx={{ mb: 2 }} />
      {!livenessPassed && (
        <Button variant="contained" color="primary" onClick={startLivenessCheck} disabled={isCapturing}>
          {isCapturing ? <CircularProgress size={24} /> : t('faceCapture.startLivenessCheck')}
        </Button>
      )}
      {livenessPassed && (
        <Button variant="contained" color="primary" onClick={handleCapture}>
          {t('faceCapture.captureSelfie')}
        </Button>
      )}
    </Box>
  );
};

export default FaceCapture;