import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  CircularProgress,
  Alert,
  Container,
  AppBar,
  Toolbar,
} from '@mui/material';
import { Phone, Work } from '@mui/icons-material';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Import Firebase modules
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential
} from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBvPoO9LIlNCl7m0a5ENwj9Rmwx8uLQsn0",
  authDomain: "angolaid-b5384.firebaseapp.com",
  projectId: "angolaid-b5384",
  storageBucket: "angolaid-b5384.appspot.com",
  messagingSenderId: "274146809631",
  appId: "1:274146809631:web:72d261858abe576d09c22a",
  measurementId: "G-KWLPVNN7BW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

interface FormData {
  phoneNumber: string;
  profession: string;
}

interface DataCollectionProps {
  onComplete: (data: any) => void;
}

const OTP_EXPIRATION_TIME = 120; // 2 minutes in seconds

const DataCollection: React.FC<DataCollectionProps> = ({ onComplete }) => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    phoneNumber: '',
    profession: '',
  });
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [otpTimer, setOtpTimer] = useState<number>(OTP_EXPIRATION_TIME);
  const [isOtpExpired, setIsOtpExpired] = useState(false);

  const recaptchaVerifier = useRef<RecaptchaVerifier | null>(null);
  const recaptchaContainerRef = useRef<HTMLDivElement>(null);

  // List of professions (ensure corresponding translations exist)
  const professions = [
    'student', 'streetVendor', 'selfEmployed', 'publicServant', 'entrepreneur',
    'teacher', 'doctor', 'nurse', 'engineer', 'farmer', 'fisherman', 'driver',
    'merchant', 'other'
  ];

  // Initialize reCAPTCHA on component mount
  useEffect(() => {
    if (!recaptchaVerifier.current && recaptchaContainerRef.current) {
      recaptchaVerifier.current = new RecaptchaVerifier(auth, recaptchaContainerRef.current, {
        size: 'normal',
        callback: () => {
          console.log('reCAPTCHA solved');
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired');
          setError(t('dataCollection.recaptchaExpired'));
          if (recaptchaVerifier.current) {
            recaptchaVerifier.current.clear();
            recaptchaVerifier.current = null;
          }
        },
        hl: i18n.language
      });
      recaptchaVerifier.current.render();
    }
  }, [t, i18n.language]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    
    if (otpSent && otpTimer > 0) {
      interval = setInterval(() => {
        setOtpTimer((prev) => {
          if (prev <= 1) {
            setIsOtpExpired(true);
            setOtpSent(false);
            clearInterval(interval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [otpSent, otpTimer]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFormData({ ...formData, phoneNumber: value });
    validatePhoneNumber(value);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setFormData({ ...formData, profession: event.target.value });
  };

  const validatePhoneNumber = (value: string) => {
    const sanitizedValue = value.replace(/\D/g, '');
    const parsedNumber = parsePhoneNumberFromString(sanitizedValue, 'AO');

    if (!parsedNumber || !parsedNumber.isValid()) {
      setPhoneError(t('dataCollection.phoneError'));
    } else {
      setPhoneError(null);
    }
  };

  const sendOTP = async () => {
    setOtpTimer(OTP_EXPIRATION_TIME);
    setIsOtpExpired(false);
    setLoading(true);
    setError(null);
    try {
      const parsedNumber = parsePhoneNumberFromString(formData.phoneNumber, 'AO');
      if (!parsedNumber || !parsedNumber.isValid()) {
        setPhoneError(t('dataCollection.invalidPhoneNumber'));
        setLoading(false);
        return;
      }
      const phoneNumber = parsedNumber.number;
      console.log('Formatted phone number:', phoneNumber);

      if (!recaptchaVerifier.current) {
        throw new Error('reCAPTCHA not initialized');
      }

      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier.current);
      setVerificationId(confirmationResult.verificationId);
      setOtpSent(true);
      console.log('OTP sent successfully.');
    } catch (error: any) {
      console.error('Error sending OTP:', error);
      handleFirebaseError(error);
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = async () => {
    setLoading(true);
    setError(null);
    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);
      console.log('OTP verified successfully.');
      handleSubmitData(true);
    } catch (error: any) {
      console.error('Error verifying OTP:', error);
      handleFirebaseError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFirebaseError = (error: any) => {
    if (error.code === 'auth/invalid-app-credential') {
      setError(t('dataCollection.invalidAppCredential'));
    } else if (error.code === 'auth/too-many-requests') {
      setError(t('dataCollection.tooManyRequests'));
    } else if (error.code === 'auth/invalid-phone-number') {
      setError(t('dataCollection.invalidPhoneNumber'));
    } else if (error.code === 'auth/invalid-verification-code') {
      setError(t('dataCollection.invalidOTP'));
    } else if (error.code === 'auth/code-expired') {
      setError(t('dataCollection.otpExpired'));
    } else {
      setError(t('dataCollection.generalError'));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!phoneError && formData.phoneNumber && formData.profession) {
      if (!otpSent) {
        sendOTP();
      } else {
        verifyOTP();
      }
    }
  };

  const handleSkipOTP = () => {
    handleSubmitData(false);
  };

  const handleSubmitData = (verified: boolean) => {
    onComplete({
      personalInfo: {
        phoneNumber: formData.phoneNumber,
        profession: formData.profession,
        phoneVerified: verified
      }
    });
  };

  return (
    <Container maxWidth="sm">

      <Card sx={{ mt: 4, mb: 4 }}>
        <CardContent sx={{ p: 4 }}>
          <Typography variant="h5" align="center" gutterBottom>
            {t('dataCollection.title')}
          </Typography>

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              fullWidth
              label={t('dataCollection.phoneLabel')}
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleTextChange}
              error={!!phoneError}
              helperText={phoneError || t('dataCollection.phoneHelperText')}
              type="tel"
              required
              placeholder={t('dataCollection.phonePlaceholder')}
              disabled={otpSent}
              InputProps={{
                startAdornment: <Phone sx={{ color: 'action.active', mr: 1 }} />,
              }}
            />

            <FormControl fullWidth>
              <InputLabel id="profession-label">{t('dataCollection.professionLabel')}</InputLabel>
              <Select
                labelId="profession-label"
                value={formData.profession}
                onChange={handleSelectChange}
                label={t('dataCollection.professionLabel')}
                required
                disabled={otpSent}
                displayEmpty
                startAdornment={<Work sx={{ color: 'action.active', mr: 1 }} />}
              >
                <MenuItem value="" disabled>
                  {t('dataCollection.professionPlaceholder')}
                </MenuItem>
                {professions.map((profession) => (
                  <MenuItem key={profession} value={profession}>
                    {t(`professions.${profession}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {!otpSent && (
              <div ref={recaptchaContainerRef}></div>
            )}

            {otpSent && (
              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  label={t('dataCollection.otpLabel')}
                  placeholder={t('dataCollection.otpPlaceholder')}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  error={isOtpExpired}
                  helperText={isOtpExpired ? t('dataCollection.otpExpired') : `${Math.floor(otpTimer / 60)}:${(otpTimer % 60).toString().padStart(2, '0')}`}
                />
              </Box>
            )}

            {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              sx={{ mt: 2 }}
              disabled={loading || !!phoneError || !formData.phoneNumber || !formData.profession || (otpSent && !otp)}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : otpSent ? (
                t('dataCollection.verifyOTP')
              ) : (
                t('dataCollection.sendOTP')
              )}
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSkipOTP}
              fullWidth
              sx={{ mt: 1 }}
              disabled={loading}
            >
              {t('dataCollection.skipOTP')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default DataCollection;